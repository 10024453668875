/* eslint-disable class-methods-use-this */
import EVENT from '../enums/event';
import STATE from '../enums/state.js';
import dispatchEvent from '../utils/dispatch-event';
import MobileMenu from './navigation-mobile.js';

const classes = {
  navOpen: 'xl:h-[650px]',
  navOpacityNone: 'xl:opacity-0',
  navHidden: 'xl:hidden',
  hidden: 'xl:hidden',
  pointerEventNone: 'xl:pointer-events-none'
};

class Megamenu extends HTMLElement {
  constructor() {
    super();

    this.state = STATE.MEGAMENU.CLOSED;
    this.breakpoint = window.matchMedia('(min-width:1280px)');
    this.previousTargetEl = null;
    this.transitioning = {
      currently: false,
      to: null,
    };

    document.addEventListener('DOMContentLoaded', () => {
      this.classList.remove(
        classes.navOpen,
        classes.hidden
      );

      this.triggers = [...document.querySelectorAll('[data-menu-trigger]')];

      this.initialiseEventListeners();
      this.initialiseTouchEventListeners();
      MobileMenu();

      window.addEventListener('resize', () => {
        this.close();
        this.previousTargetEl = null;
      });
    });
  }

  openMenu(key) {
    const menus = [...document.querySelectorAll('[data-menu]')];

    menus.forEach(menu => menu.classList.add(classes.hidden));
    menus
      .find(menu => menu.dataset.menu === key)
      .classList.remove(classes.hidden);
  }

  setTransitioning(currently, to = null) {
    this.transitioning = {
      currently,
      to,
    };
  }

  open() {
    this.classList.add(classes.navOpen);
    this.classList.remove(classes.navOpacityNone);
    this.classList.remove(classes.pointerEventNone);
    this.classList.remove(classes.navHidden);

    this.state = STATE.MEGAMENU.OPEN;
    this.setTransitioning(false);
    window.addEventListener('scroll', () => this.closeOnScroll());
  }

  closeWithDelay() {
    setTimeout(() => {
      if (!this.transitioning.currently) {
        return;
      }

      this.close();
      this.classList.add(classes.pointerEventNone);
    }, 250);
  }

  close() {
    this.classList.remove(classes.navOpen);
    this.classList.add(classes.navOpacityNone);

    this.state = STATE.MEGAMENU.CLOSED;

    this.setTransitioning(false);

    window.removeEventListener('scroll', () => this.closeOnScroll());
  }

  closeImmediately() {
    this.classList.add('invisible', 'hidden');

    this.close();
    setTimeout(() => {
      this.classList.remove('invisible', 'hidden');
    }, 400);
  }

  closeOnScroll() {
    this.closeImmediately();
  }

  transitionState(from, to) {
    const wasAlreadyTransitioning = this.transitioning.currently;

    this.setTransitioning(true, to);

    if (to === STATE.MEGAMENU.OPEN) {
      return this.open();
    }

    if (to === STATE.MEGAMENU.CLOSED && wasAlreadyTransitioning) {
      this.setTransitioning(true, STATE.MEGAMENU.OPEN);
      return this.open();
    }

    return this.closeWithDelay();
  }

  initialiseTouchEventListeners() {
    if (this.breakpoint.matches) {
      this.triggers.forEach(trigger =>
        this.addTriggerTouchEventListeners(trigger)
      );
    }
  }

  initialiseEventListeners() {
    this.triggers.forEach(trigger => this.addTriggerEventListeners(trigger));

    this.addEventListener('mouseover', () => {
      this.transitionState(this.state, STATE.MEGAMENU.OPEN);
    });

    this.addEventListener('mouseout', () => {
      this.transitionState(this.state, STATE.MEGAMENU.CLOSED);
    });
  }

  addTriggerEventListeners(trigger) {
    ['mouseenter', 'mouseleave'].forEach(ev => {
      trigger.addEventListener(ev, () => {
        this.openMenu(trigger.dataset.menuTrigger);
        this.transitionState(this.state, Number(!this.state));
      });
    });
  }

  addTriggerTouchEventListeners(trigger) {
    trigger.addEventListener('touchstart', e => {
      e.preventDefault();

      if (this.previousTargetEl === e.target) {
        this.close();
        this.previousTargetEl = null;
      } else if (this.state === STATE.MEGAMENU.CLOSED) {
        this.openMenu(trigger.dataset.menuTrigger);
        this.transitionState(this.state, Number(!this.state));
        dispatchEvent(EVENT.HEADER.WHITE, document);
        this.previousTargetEl = e.target;
      } else {
        this.openMenu(trigger.dataset.menuTrigger);
        this.previousTargetEl = e.target;
      }
    });
  }
}

customElements.define('mega-menu', Megamenu);
